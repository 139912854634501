<template>
  <div class="featuresinfo">
    <div class="featuresinfo_center">
      <!-- <h1>详情</h1> -->

      <header>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/features' }">特色项目</el-breadcrumb-item>
          <el-breadcrumb-item>{{ nowtitle }}</el-breadcrumb-item>
        </el-breadcrumb>
      </header>
      <div class="feats">
        <aside>
          <div class="fealist">
            <div class="uplsit">
              <p>
                <i class="i_userin"></i>
                <span class="i_user">{{updata.user}}</span>
              </p>
              <p>
                <span class="i_time">{{updata.time}}</span>
              </p>
            </div>
            <p class="featpt">{{updata.title}}</p>
            <p class="featpc">{{updata.content}}</p>
            <div class="feaIcon">
              <div class="feaLeft">
                <p class="i_like">
                  <i></i>
                  <span>{{updata.like}}</span>
                </p>
                <p class="i_collect">
                  <i></i>
                  <span>{{updata.collect}}</span>
                </p>
                <p class="i_pinglun">
                  <i></i>
                  <span>{{updata.pinglun}}</span>
                </p>
              </div>
              <div class="feaRight">
                <p>
                  <i class="i_down"></i>
                  <span>资料下载</span>
                </p>
              </div>
            </div>
          </div>

          <div>
            <div class="fealist" v-for="item in fealistData" :key="item.id">
              <div class="uplsit">
                <p>
                  <i class="i_userin"></i>
                  <span class="i_user">{{item.user}}</span>
                </p>
                <p>
                  <span class="i_time">{{item.time}}</span>
                </p>
              </div>
              <p class="featpc">{{item.content}}</p>
              <div class="feaIcon">
                <div class="feaLeft">
                  <p class="i_like">
                    <i></i>
                    <span>{{item.like}}</span>
                  </p>
                </div>
                <div class="feaRight">
                  <p>
                    <i class="i_del"></i>
                    <span>删除</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="curpage"
            :page-size="pagesize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </aside>
        <main>
          <div class="hotlist">
            <p class="hottwxt">热门话题</p>
            <div class="hottext">
              <p v-for="item in hotlistdata" :key="item.id" @click="cutdata(item.id)">{{item.title}}</p>
            </div>
          </div>
        </main>
      </div>
      <footer>
        <p class="f_title">
          <i></i>
          <span>发布回复</span>
        </p>
        <div class="f_i_title">
          <el-input v-model="talkform.title" placeholder="请输入标题" size="mini"></el-input>
        </div>
        <div class="f_i_title">
          <!-- <el-input v-model="talkform.content" placeholder="请输入内容" size="mini"></el-input> -->
          <myEditor @gethtml="htmltext"></myEditor>
        </div>
        <el-button @click="saveCircleTalk" size="mini" type="primary">发布</el-button>
      </footer>
    </div>
  </div>
</template>
<script>
import myEditor from "./components/myeditor.vue";
export default {
  components: {
    myEditor,
  },
  data() {
    return {
      //面包屑
      nowtitle: null,
      //详情列表
      fealistData: null, //回复数据
      updata: null, //楼主的数据
      hotlistdata: null, //热门数据
      // 分页
      total: null,
      pagesize: 10,
      curpage: 1,
      // 发布
      talkform: {
        title: null,
      },
    };
  },
  created() {
    this.nowtitle = this.$route.query.title;
    // this.$route.query.id;
    this.getcontentdata();
    this.getdata();
    this.gethotlist();
  },
  methods: {
    //
    htmltext(val) {
      console.log(val, "富文本的html");
    },
    // 话题楼主
    getdata() {
      console.log(this.$route.query.id, "this.$route.query.id;");
      let aa = {
        id: 1,
        title: "关于区域卓越教师队伍建设的探讨",
        content:
          "北京市朝阳区持续推动，促进区域卓越教师队伍的建设，目前朝阳区教育系统已经完成了规模较大、规格较高的人才培训与储备。对于建设卓越教师队伍您有何看法或建议，或者您认为的卓越教师应该符合什么标准？请大家畅所欲言。",
        like: 0,
        collect: 1,
        pinglun: 1,
        user: "刘老师",
        time: "2022-03-07 20:20",
      };
      this.updata = aa;
    },
    // 话题回复详情
    getcontentdata() {
      let aa = [
        //
        {
          id: 1,
          user: "刘老师",
          time: "2022-03-07 20:20",
          content:
            "我认为卓越教师标准之一就是其应该掌握一套科学的教育教学方法，同时该经过实践检验的教育教学方法应该具备可推广性，可以普及给广大普通教师。",
          like: 200,
        },
      ];
      this.fealistData = aa;
      this.total = aa.length;
    },
    //热门话题列表
    gethotlist() {
      let aa = [
        //
        {
          id: 1,
          title: "推动卓越教师培养计划",
        },
        {
          id: 2,
          title: "关于区域卓越教师队伍建设的探讨",
        },
      ];
      this.hotlistdata = aa;
    },
    //发布回复
    saveCircleTalk() {
      console.log(this.talkform, "发布回复");
      this.$message.success("该功能尚未开放");
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.featuresinfo {
  .featuresinfo_center {
    width: 1200px;
    margin: 0 auto;
    header {
      padding: 15px 0;
    }
    .feats {
      display: flex;
      justify-content: space-between;
      aside {
        width: 727px;
        // height: 1918px;
        // min-height: 600px;
        background: #ffffff;
        padding: 0 30px;
        .fealist {
          border-bottom: 1px solid #d7dce4;
          padding: 30px 0 10px;
          .uplsit {
            display: flex;
            justify-content: flex-start;
            p {
              width: 100px;
            }
            .i_userin {
              display: inline-block;
              width: 39px;
              height: 39px;
              background: url("../../assets/feature/user.png") no-repeat;
              vertical-align: middle;
            }
            .i_user {
              display: inline-block;
              width: 36px;
              height: 16px;
              font-size: 12px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #5d6066;
              line-height: 14px;
              // vertical-align: middle;
              margin-left: 5px;
            }
            .i_time {
              display: inline-block;
              width: 102px;
              height: 16px;
              font-size: 12px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #8d9199;
              line-height: 39px;
            }
          }
          .featpt {
            width: 280px;
            margin-top: 14px;
            // height: 18px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 600;
            color: #141516;
            // line-height: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            // cursor: pointer;
          }
          .featpc {
            width: 720px;
            // height: 56px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #5d6066;
            // line-height: 14px;
            margin-top: 13px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
          .feaIcon {
            margin-top: 16px;
            display: flex;
            justify-content: space-between;
            .feaLeft {
              display: flex;
              justify-content: space-around;
              p {
                width: 50px;
              }
              .i_like {
                i {
                  display: inline-block;
                  width: 16px;
                  height: 16px;
                  background: url("../../assets/feature/like.png") no-repeat;
                  vertical-align: middle;
                }
              }
              .i_collect {
                i {
                  display: inline-block;
                  width: 16px;
                  height: 16px;
                  background: url("../../assets/feature/collect.png") no-repeat;
                  vertical-align: middle;
                }
              }
              .i_pinglun {
                i {
                  display: inline-block;
                  width: 16px;
                  height: 16px;
                  background: url("../../assets/feature/pinglun.png") no-repeat;
                  vertical-align: middle;
                }
              }
              span {
                margin-left: 3px;
                display: inline-block;
                width: 22px;
                height: 16px;
                font-size: 12px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #8d9199;
                line-height: 14px;
                vertical-align: middle;
              }
            }
            .feaRight {
              p {
                width: 100px;
                cursor: pointer;
              }
              .i_down {
                display: inline-block;
                width: 17px;
                height: 16px;
                background: url("../../assets/feature/download.png") no-repeat;
                vertical-align: middle;
              }
              .i_del {
                display: inline-block;
                width: 19px;
                height: 18px;
                background: url("../../assets/feature/del.png") no-repeat;
                vertical-align: middle;
              }
              span {
                display: inline-block;
                margin-left: 4px;
                width: 48px;
                height: 16px;
                font-size: 12px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #5d6066;
                line-height: 14px;
              }
            }
          }
        }
      }
      main {
        width: 392px;
        // max-height: 499px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        .hotlist {
          background: #ffffff;
          padding: 15px 20px;
          .hottwxt {
            // width: 72px;
            height: 24px;
            font-size: 18px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #141516;
            line-height: 21px;
            border-bottom: 1px solid #d7dce4;
            padding: 10px 0;
          }
          .hottext {
            padding: 10px 0;
            p {
              width: 320px;
              height: 30px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #141516;
              line-height: 30px;
              cursor: pointer;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            p:hover {
              color: #1977f9;
            }
          }
        }
      }
    }
    footer {
      width: 747px;
      margin-top: 25px;
      background-color: #fff;
      padding: 20px;
      .f_title {
        i {
          display: inline-block;
          width: 18px;
          height: 18px;
          vertical-align: middle;
          //   background: url('../../assets');
          background: url("../../assets/icon/replytalk.png") no-repeat;
        }
        span {
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px;
          // width: 80px;
          // height: 21px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #141516;
          // line-height: 19px;
        }
      }
      .f_i_title {
        margin: 10px 0;
      }
      .el-button {
        margin-top: 10px;
      }
    }
    .el-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
    }
  }
}
</style>